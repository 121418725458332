<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="api">API</v-tab>
            <v-tab key="location">Location</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="api">
              <v-editable-pad v-model="api" title="API" v-slot="{data,rules,disabled}" @save="saveApi">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="data.default.url" label="API Base URL" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.localDevice.url" label="Local device API URL" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.authentication.url" label="OAuth2 API URL" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.authentication.clientId" label="OAuth2 Client ID" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.authentication.clientSecret" label="OAuth2 Client Secret" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.pacs.url" label="PACS API Base URL" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.pacsViewer.url" label="PACS Viewer URL" :readonly="disabled"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-editable-pad>
            </v-tab-item>
            <v-tab-item key="location">
              <v-editable-pad v-model="location" title="Location" v-slot="{data,rules,disabled}" @save="saveLocation">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-master-field v-model="data.locationCode" :custom-api="['models','Master','Locations','query']"
                        custom-api-text="locationName" custom-api-value="locationCode"
                        customApiEagerLoad
                        :value-object.sync="currentLocation"
                        label="สถานที่" show-code
                        :readonly="disabled"
                        :clearable="!disabled"
                      ></v-master-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select label="สถานที่ย่อย"
                        :items="(currentLocation) ? currentLocation.subunits : undefined"
                        v-model="data.locationSubunitCode"
                        item-text="subunitName"
                        item-value="subunitCode"
                        :readonly="disabled"
                        :clearable="!disabled"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="data.cashierId" label="Cashier ID" :readonly="disabled"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-master-field v-model="data.stockId" 
                        :custom-api="['models','Stock','Stocks','query']"
                        custom-api-text="stockName" 
                        custom-api-value="id"
                        customApiEagerLoad
                        label="คลังสินค้า" show-code
                        :readonly="disabled"
                        :clearable="!disabled"
                      ></v-master-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-editable-pad>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isUndefined } from 'lodash'
export default {
  data: ()=>({
    api: {
      default: {},
      authentication: {},
      localDevice: {},
      pacs: {},
      pacsViewer: {},
    },
    location: {},
    currentLocation: undefined,
  }),
  methods: {
    saveConfig(conName,conData) {
      this.$store.commit('addConfig',{
        configName: conName,
        configItems: conData
      })

      this.$store.dispatch('addAlertItem',{
        alertLocation: 'top',
        alertType: 'success',
        alertText: 'Successfully save '+conName+' configuration'
      })
    },
    saveApi(conData) {
      this.saveConfig('api',conData)
    },
    saveLocation(conData) {
      this.saveConfig('location',conData)
    }
  },
  beforeMount() {
    this.api = this.$store.getters.getConfig('api')
    this.location = this.$store.getters.getConfig('location')
  },
}
</script>

<style lang="css" scoped>
</style>
